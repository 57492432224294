import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchConfig() {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/page-setups/payout-methods-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/processing/payout-methods', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/processing/payout-methods/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  }
}